<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <div class="row">
      <div class="col-12 f13">
        <div class="card concard card-shadow">
          <div class="card-header white text-center">
            <img src="@/assets/logo.png" style="height: 100px" alt="..." />
            <div class="t1" style="margin: 5px">แก้ไขสัตว์เลี้ยง</div>
          </div>
          <Form
            @submit="onSubmitRegister"
            :validation-schema="register"
            id="kt_account_profile_details_form"
            ref="formContainer"
            novalidate="novalidate"
          >
            <div class="card-body mb-47 mt-2 white">
              <!--begin::Card body-->
              <div class="card-body">
                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >ชื่อสัตว์เลี้ยง</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field type="hidden" name="id" v-model="data.id" />
                    <Field
                      type="text"
                      name="name"
                      class="form-control form-control-solid"
                      placeholder="ชื่อสัตว์เลี้ยง"
                      v-model="data.name"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >ประเภท</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field
                      as="select"
                      name="type"
                      class="form-control form-control-solid"
                      v-model="data.type"
                    >
                      <option value="" selected disabled>โปรดเลือก</option>
                      <option value="หมา">หมา</option>
                      <option value="แมว">แมว</option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="type" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">สายพันธุ์</label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field
                      type="text"
                      name="species"
                      class="form-control form-control-solid"
                      placeholder="สายพันธุ์"
                      v-model="data.species"
                    />
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">เพศ</label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field
                      as="select"
                      name="gender"
                      class="form-control form-control-solid"
                      v-model="data.gender"
                    >
                      <option value="" selected disabled>โปรดเลือก</option>
                      <option value="เพศผู้">เพศผู้</option>
                      <option value="เพศเมีย">เพศเมีย</option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="gender" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6"
                    >แนบรูปสัตว์เลี้ยง</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->

                  <div class="col-lg-8 fv-row">
                    <Field type="hidden" name="imageUrl" v-model="imageUrl" />
                    <div
                      v-if="imageUrl"
                      class="imagePreviewWrapper"
                      style="padding: 15px 0px"
                    >
                      <div class="row">
                        <div style="position: relative" class="col-12">
                          <div class="xx" @click="deleteImage()">
                            <span>x</span>
                          </div>
                          <img class="images" :src="imageUrl" />
                        </div>
                      </div>
                    </div>
                    <input
                      type="file"
                      id="getFile"
                      accept="image/jpg, image/jpeg, image/png"
                      @input="pickFile"
                      style="display: none"
                    />
                    <div class="d-flex justify-center align-center" v-if="!imageUrl">
                      <div style="width: 100%; padding: 0px 5px">
                        <button
                          type="button"
                          @click="selectImage"
                          class="form-control btn btn-outline-secondary"
                        >
                          แนบรูปสัตว์เลี้ยง<img
                            src="@/assets/picture.png"
                            class="icon-ima"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="imageUrl" />
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
            </div>
            <div class="card-footer white">
              <div class="d-flex justify-center align-center">
                <div style="width: 100%; padding: 0px 5px">
                  <button type="button" @click="back" class="button btn-back">
                    ย้อนกลับ
                  </button>
                </div>
                <div style="width: 100%; padding: 0px 5px">
                  <button type="submit" style="width: 100%" class="button">แก้ไข</button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import useSweetalert from "@/service/sweetalert2";
import useApi from "@/service/api/register";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const imageUrl = ref("");
    const route = useRoute();
    const { Sconfirm, SconfirmNc } = useSweetalert();
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const { getData, updateData } = useApi();
    const id = ref(route.params.id);
    const data = ref("");

    const register = Yup.object().shape({
      name: Yup.string().required("กรุณากรอก ชื่อสัตว์เลี้ยง"),
      type: Yup.string().required("กรุณาเลือก ประเภท"),
      gender: Yup.string().required("กรุณาเลือก เพศ"),
      imageUrl: Yup.string().required("กรุณาแนบรูป สัตว์เลี้ยง"),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
      getData(id.value).then((res) => {
        data.value = res.data.data;
        imageUrl.value = res.data.data.image;
        isLoading.value = false;
      });
    });

    const onSubmitRegister = (values) => {
      Sconfirm("ยืนยัน การแก้ไขข้อมูล", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          values.id_line = store.getters.getAccessToken.accesstoken;

          await updateData(values)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("แก้ไขข้อมูลสำเร็จ", "success", "ตกลง").then(() => {
                  router.push({ name: "Register_pet" });
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง");
              }
            })
            .catch(() => {
              SconfirmNc("เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง", "error", "ตกลง");
            });
        }
      });
    };
    const back = () => {
      router.push({ name: "Register_pet" });
    };

    const selectImage = () => {
      document.getElementById("getFile").click();
    };
    const deleteImage = () => {
      imageUrl.value = "";
    };
    const pickFile = async (e) => {
      const files = e.target.files;

      if (files) {
        imageUrl.value = await reduce_image_file_size(await image_to_base64(files[0]));
      }
    };

    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

    return {
      onSubmitRegister,
      register,
      selectImage,
      deleteImage,
      pickFile,
      imageUrl,
      back,
      isLoading,
      color,
      data,
    };
  },
});
</script>

<style scoped>
.images {
  width: 100%;
}
.xx {
  position: absolute;
  background-color: #bd313e;
  color: white;
  right: 12px;
  padding: 6px;
}
</style>
