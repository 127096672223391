<script setup>
import { onMounted, ref, inject } from "vue";
import moment from "moment";
import useApi from "@/service/api/history";
import { useRoute } from "vue-router";
import useSweetalert from "@/service/sweetalert2";
import { Field } from "vee-validate";
const route = useRoute();
const id = ref(route.params.id);
const { getData_vaccination, cancel_Queue_vaccination } = useApi();
const store = inject("store");
const router = inject("router");

const data = ref("");
const department = ref("");
let isLoading = ref(true);
let color = ref(process.env.VUE_APP_COLOR);
const { SmessIn, SconfirmNc } = useSweetalert();

onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
  getData_vaccination(id.value).then((res) => {
    data.value = res.data.data;
    department.value = res.data.department;
    isLoading.value = false;
  });
});

const back = () => {
  router.push({ name: "History" });
};

const cancel = () => {
  SmessIn().then((res) => {
    if (res.isConfirmed) {
      if (!res.value) {
        SconfirmNc("กรุณากรอกเหตุผลการยกเลิกคิว", "error", "ตกลง");
      } else {
        isLoading.value = true;
        let values = {
          id: id.value,
          id_line: store.getters.getAccessToken.accesstoken,
          detail: res.value,
        };

        cancel_Queue_vaccination(values).then((result) => {
          isLoading.value = false;
          if (result.data.status === "success") {
            SconfirmNc("ยกเลิกคิวสำเร็จ", "success", "ตกลง").then(() => {
              isLoading.value = true;
              getData_vaccination(result.data.data.id).then((res) => {
                data.value = res.data.data;
                department.value = res.data.department;
                isLoading.value = false;
              });
            });
          }
        });
      }
    }
  });
};

function dateThai(date) {
  let y = moment(date).format("yyy");
  let year = parseInt(y) + 543;
  let march = moment(date).locale("th");

  return march.format("dddที่ D MMMM พ.ศ. " + year + " เวลา HH : mm : ss");
}

function dateThai1(date) {
  let y = moment(date).format("yyy");
  let year = parseInt(y) + 543;
  let march = moment(date).locale("th");

  return march.format("ddd D MMM พ.ศ. " + year);
}
</script>
<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>

    <div class="row">
      <div class="col-12">
        <div class="card concard card-shadow">
          <Header></Header>

          <div class="text-start mn-profile">
            <span class="text-root mini-h"> จองคิวการฉีดวัคซีนสัตว์เลี้ยง </span>
          </div>
          <div class="card-body mt-2 mb-47 white" v-if="data">
            วันที่จอง : {{ dateThai(data.created_at) }}

            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">รูป</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <div class="image">
                  <img :src="data.pet.image" class="image-logo" alt="" />
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ชื่อสัตว์เลี้ยง</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">
                  {{ data.pet.name }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ประเภท</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">
                  {{ data.pet.type }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">เพศ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">
                  {{ data.pet.gender }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">วันที่</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">
                  {{ dateThai1(data.dateST) }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">สถานะ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span
                  class="fw-bolder fs-6"
                  v-if="data.status === 'ยืนยันแล้ว'"
                  style="color: blue"
                >
                  {{ data.status }}
                </span>
                <span
                  class="fw-bolder fs-6"
                  v-else-if="data.status === 'เสร็จสิ้น'"
                  style="color: green"
                >
                  {{ data.status }}
                </span>
                <span class="fw-bolder fs-6" v-else style="color: red">
                  {{ data.status }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div
              class="row mb-7 mt-2"
              v-if="department.doc && data.status === 'ยืนยันแล้ว'"
            >
              <!--begin::Label-->
              <label class="col-12 fw-bold text-muted"
                >รายละเอียดเอกสารที่ต้องเตรียม</label
              >
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-12">
                <Field
                  name="doc"
                  class="form-control form-control-lg form-control-solid"
                  style="font-size: 14px"
                  v-model="department.doc"
                  as="textarea"
                  rows="5"
                  disabled="disabled"
                />
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7 mt-2" v-if="data.status === 'ยืนยันแล้ว'">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted"></label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <button type="button" @click="cancel" class="btn btn-danger">
                  ยกเลิกคิว
                </button>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7 mt-3" v-if="data.detail">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">รายละเอียด</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">
                  {{ data.detail }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7 mt-3" v-if="data.cancel_by">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted"></label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6" style="color: red">
                  {{ data.cancel_by }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="button" class="button" @click="back">ย้อนกลับ</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 15px;
}
</style>
