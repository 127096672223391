import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default function useSweetalert() {
  const Sconfirm = (text, icon) => {
    return Swal.fire({
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#009EF7",
      cancelButtonColor: "#F1416C",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก!",
      reverseButtons: true,
    });
  };
  const SconfirmNc = (text, icon, textbt) => {
    return Swal.fire({
      text: text,
      icon: icon,
      buttonsStyling: false,
      confirmButtonText: textbt,
      confirmButtonColor: "#009EF7",
      customClass: {
        confirmButton: "btn fw-bold btn-light-primary",
      },
    });
  };

  const SToast = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon,
      title: title,
    });
  };

  const SmessIn = async () => {
    return await Swal.fire({
      input: "textarea",
      inputLabel: "ยกเลิกคิว",
      inputPlaceholder: "เหตุผล...",
      inputAttributes: {
        "aria-label": "เหตุผล",
      },
      confirmButtonColor: "#009EF7",
      cancelButtonColor: "#F1416C",
      confirmButtonText: "ยืนยัน",
      showCancelButton: true,
      cancelButtonText: "ยกเลิก!",
      reverseButtons: true,
    });
  };

  return { Sconfirm, SToast, SconfirmNc , SmessIn };
}
