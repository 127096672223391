import ApiService from "@/service/ApiService";

export default function useApi() {


  const getDatas = async () => {
    return await ApiService.get("get_sterilization_queue_history");
  };
  const getData = async (id) => {
    return await ApiService.get("get_sterilization_queue_history/" +id);
  };
  const getData_finish = async () => {
    return await ApiService.get("get_sterilization_queue_finish_history");
  };

  const getData_vaccinations = async () => {
    return await ApiService.get("get_vaccination_queue_history");
  };

  const getData_vaccination = async (id) => {
    return await ApiService.get("get_vaccination_queue_history/" +id);
  };

  const getData_vaccination_finish = async () => {
    return await ApiService.get("get_vaccination_queue_finish_history");
  };


  const cancel_Queue = async (data) => {
    return await ApiService.put("cancel_queue_sterilization/" + data.id, data);
  };

  const cancel_Queue_vaccination = async (data) => {
    return await ApiService.put("cancel_queue_vaccination/" + data.id, data);
  };




  return {
    getDatas,
    getData,
    getData_vaccinations,
    getData_vaccination,
    getData_finish,
    getData_vaccination_finish,
    cancel_Queue,
    cancel_Queue_vaccination
   
  };
}
