import ApiService from "@/service/ApiService";

export default function useApi() {

  const Register = async (data) => {
    return await ApiService.put("register_queue_pet/" + data.id_line, data);
  };



  const getDatas = async () => {
    return await ApiService.get("register_pet");
  };
  const getData = async (id) => {
    return await ApiService.get("register_pet/"+ id);
  };
  const storeData = async (data) => {
    return await ApiService.post("register_pet", data);
  };

  const updateData = async (data) => {
    return await ApiService.put("register_pet/" + data.id, data);
  };



  


  return {
    getDatas,
    getData,
    Register,
    storeData,
    updateData
  };
}
