<script setup>
import liff from "@line/liff";

const setProfile = () => {
  liff.ready.then(() => {
    liff.openWindow({
      url: `line://app/${process.env.VUE_APP_LIFF_REGISTER}`,
      external: false,
    });
  });
};
const AppName = process.env.VUE_APP_NAME;
</script>

<template>
  <div class="card-header text-end">
    <img src="@/assets/bg.jpg" class="image-bg" alt="image" />
    <div>
      <div class="row header-logo-text mt-3">
      <div class="col-5" style="padding: 0">
        <div class="text-center">
          <img src="@/assets/logo.png" alt="image" class="img-profile" />
        </div>
      </div>
      <div class="col-7 text-start" style="padding: 0">
        <div class="row">
          <span class="title-home mt-3">{{ AppName }}</span>
        </div>
      </div>
    </div>
      <i @click="setProfile" class="bi bi-gear icon"></i>
    </div>
  </div>
</template>

<style scoped></style>
