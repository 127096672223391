import { createStore } from "vuex";

import ProfireLine from "@/store/modules/ProfireLine";
import AuthModule from "@/store/modules/AuthModule";
import RegisterModel from "@/store/modules/RegisterModel";
import AddHomeModel from "@/store/modules/AddHomeModel";


const store = createStore({
  modules: {
    ProfireLine,
    AuthModule,
    RegisterModel,
    AddHomeModel
  },
});

export default store;
