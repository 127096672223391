export const SET_HOME = "setHome";

export default {
  state: {
    home: {
      province: "สงขลา",
    },
  },
  getters: {
    getHome(state) {
      return state.home;
    },
  },
  actions: {
    [SET_HOME](state, payload) {
      state.commit(SET_HOME, payload);
    },
  },
  mutations: {
    [SET_HOME](state, payload) {
      state.home = {
        ...state.home,
        ...payload,
      };
    },
  },
};
