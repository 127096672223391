<script setup>
import { onMounted, inject } from "vue";
import liff from "@line/liff";
const store = inject("store");
const router = inject("router");

onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
});

const close = () => {
  liff.ready.then(() => {
    liff.closeWindow();
  });
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>

        <div class="text-start mn-profile">
          <span class="text-root mini-h"> งานสัตวแพทย์ ออนไลน์ </span>
        </div>
        <div class="card-body mb-47 mt-2">
          <div style="padding: 20px" class="mt-3 white">
            <router-link
              :to="{
                name: 'Register_pet',
              }"
            >
              <button type="button" style="width: 100%" class="bbtn btn btn-success">
                ลงทะเบียนสัตว์เลี้ยง
              </button>
            </router-link>
          </div>

          <div style="padding: 20px" class="mt-3 white">
            <router-link
              :to="{
                name: 'Queue_sterilization',
              }"
            >
              <button type="button" style="width: 100%" class="bbtn btn btn-primary">
                จองคิวการทำหมันสัตว์เลี้ยง
              </button>
            </router-link>
          </div>

          <div style="padding: 20px" class="mt-3 white">
            <router-link
              :to="{
                name: 'Queue_vaccination',
              }"
            >
              <button type="button" style="width: 100%" class="bbtn btn btn-info">
                จองคิวการฉีดวัคซีนสัตว์เลี้ยง
              </button>
            </router-link>
          </div>

          <div style="padding: 20px" class="mt-3 white">
            <router-link
              :to="{
                name: 'History',
              }"
            >
              <button type="button" style="width: 100%" class="bbtn btn btn-warning">
                ตรวจสอบประวัติการจองคิว
              </button>
            </router-link>
          </div>
        </div>
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button type="button" class="button" @click="close">ปิดหน้านี้</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  padding: 0;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  height: 16rem;
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 33.33%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.history-image {
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}
.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 50%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 50%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}
.history-detail {
  margin-left: 10px;
}
.history-footer {
  font-size: 12px;
  padding: 10px;
  margin-left: 5px;
}
.history-botton {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.see-more {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}

.bbtn {
  font-family: Sarabun, sans-serif;
  font-weight: 500;
  font-size: 18px !important;
  border-radius: 8px;
  color: #fff;
  height: 44px !important;
  min-height: 44px !important;
  padding: 0 14px !important;
  letter-spacing: 0;
  text-indent: 0;
  text-transform: none;
  flex: 1;
}
</style>
