<script setup>
import { onMounted, inject, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import useApi from "@/service/api/vaccination";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { th } from "date-fns/locale";
import useSweetalert from "@/service/sweetalert2";

const store = inject("store");
const router = inject("router");
const { getDatas, stoerData } = useApi();
const datas = ref("");
const pets = ref("");
const dateDisable = ref([]);
let isLoading = ref(true);
let color = ref(process.env.VUE_APP_COLOR);
const { Sconfirm, SconfirmNc } = useSweetalert();

const data = Yup.object().shape({
  id_pet: Yup.string().required("กรุณาเลือก สัตว์เลี้ยง"),
  dateST: Yup.date().required("กรุณาเลือก วันที่").typeError("กรุณาเลือก วันที่"),
});

const dateST = ref();

onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
  getDatas().then((response) => {
    datas.value = response.data.data;
    pets.value = response.data.pets;
    let dataa = new Set(JSON.parse(response.data.data.day_on_off));
    const dateAll = [0, 1, 2, 3, 4, 5, 6];
    dateDisable.value = dateAll.filter((x) => !dataa.has(x));
    isLoading.value = false;
  });
});

const back = () => {
  router.push({ name: "Profile" });
};

const onSubmit = (values) => {
  Sconfirm("ยืนยัน การจองคิว", "question").then(async (result) => {
    if (result.isConfirmed) {
      isLoading.value = true;
      values.id_line = store.getters.getAccessToken.accesstoken;
      await stoerData(values).then((result) => {
        isLoading.value = false;
        if (result.data.status === "success") {
          SconfirmNc("จองคิวสำเร็จ", "success", "ตกลง").then(() => {
            isLoading.value = false;
            router.push({
              name: "History_detail_vaccination",
              params: { id: result.data.id },
            });
          });
        } else {
          SconfirmNc(result.data.message, "error", "ตกลง");
        }
      });
    }
  });
};

const minDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 2);
  return date;
};
</script>
<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <div class="row">
      <div class="col-12 f13">
        <div class="card concard card-shadow">
          <div class="card-header white text-center">
            <img src="@/assets/logo.png" style="height: 100px" alt="..." />
            <div class="t1" style="margin: 5px">การฉีดวัคซีนสัตว์เลี้ยง</div>
          </div>
          <Form
            @submit="onSubmit"
            :validation-schema="data"
            id="kt_account_profile_details_form"
            ref="formContainer"
            novalidate="novalidate"
          >
            <div class="card-body mb-47 mt-2 white">
              <!--begin::Card body-->
              <div class="card-body">
                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >สัตว์เลี้ยง</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field
                      as="select"
                      name="id_pet"
                      class="form-control form-control-solid"
                    >
                      <option value="" selected disabled>โปรดเลือก</option>
                      <option v-for="pet in pets" :key="pet.id" :value="pet.id">
                        {{ pet.type }} ({{ pet.gender }}) | {{ pet.name }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="id_pet" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >วันที่</label
                  >
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field type="hidden" name="dateST" v-model="dateST" />
                    <Datepicker
                      locale="th"
                      :enableTimePicker="false"
                      placeholder="เลือกวัน"
                      :format-locale="th"
                      cancelText="ยกเลิก"
                      selectText="เลือก"
                      v-model="dateST"
                      :minDate="minDate()"
                      :disabledWeekDays="dateDisable"
                    >
                    </Datepicker>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="dateST" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
              </div>
              <!--end::Card body-->
            </div>
            <div class="card-footer white">
              <div class="d-flex justify-center align-center">
                <div style="width: 100%; padding: 0px 5px">
                  <button type="button" @click="back" class="button btn-back">
                    ย้อนกลับ
                  </button>
                </div>
                <div style="width: 100%; padding: 0px 5px">
                  <button type="submit" style="width: 100%" class="button">จองคิว</button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
