import ApiService from "@/service/ApiService";

export default function useApi() {


  const getDatas = async () => {
    return await ApiService.get("get_vaccination_departments");
  };

  const stoerData = async (data) => {
    return await ApiService.post("vaccination_queue", data);
   };
 

  return {
    getDatas,
    stoerData
   
  };
}
