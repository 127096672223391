import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

import Profile from "@/views/profile/Profile.vue";
import Register from "@/views/register/Index.vue";

import Register_pet from "@/views/register_pet/Index.vue";
import Register_pet_add from "@/views/register_pet/Add.vue";
import Register_pet_edit from "@/views/register_pet/Edit.vue";

import Queue_vaccination from "@/views/queue_vaccination/Index.vue";
import Queue_sterilization from "@/views/queue_sterilization/Index.vue";

import History from "@/views/history/Index.vue";
import History_detail from "@/views/history/Show.vue";
import History_detail_vaccination from "@/views/history/Show_vaccination.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register_pet",
    name: "Register_pet",
    component: Register_pet,
  },
  {
    path: "/register_pet_add",
    name: "Register_pet_add",
    component: Register_pet_add,
  },
  {
    path: "/register_pet_add/:id",
    name: "Register_pet_edit",
    component: Register_pet_edit,
  },

  {
    path: "/queue_vaccination",
    name: "Queue_vaccination",
    component: Queue_vaccination,
  },
  {
    path: "/queue_sterilization",
    name: "Queue_sterilization",
    component: Queue_sterilization,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/history/:id",
    name: "History_detail",
    component: History_detail,
  },
  {
    path: "/history_vaccination/:id",
    name: "History_detail_vaccination",
    component: History_detail_vaccination,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
