<script setup>
import { onMounted, ref, inject } from "vue";
import useApi from "@/service/api/register";
const { getDatas } = useApi();
const store = inject("store");
const router = inject("router");

const datas = ref([]);
let isLoading = ref(true);
let color = ref(process.env.VUE_APP_COLOR);

onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
  getDatas().then((res) => {
    datas.value = res.data.data;
    isLoading.value = false;
  });
});

const back = () => {
  router.push({ name: "Profile" });
};
</script>
<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <div class="row">
      <div class="col-12">
        <div class="card concard card-shadow">
          <Header></Header>

          <div class="text-start mn-profile">
            <span class="text-root mini-h"> งานสัตวแพทย์ ออนไลน์ </span>
          </div>
          <div class="card-body mb-47 mt-2">
            <div style="padding: 20px" class="mt-3 white">
              <router-link
                :to="{
                  name: 'Register_pet_add',
                }"
              >
                <button type="button" style="width: 100%" class="bbtn btn btn-success">
                  เพิ่มสัตว์เลี้ยง
                </button>
              </router-link>
            </div>

            <template v-if="datas.length > 0">
              <div class="mb-3">
                <div
                  class="card-body mt-3 white"
                  v-for="(data, index) in datas"
                  :key="index"
                >
                  <div class="history">
                    <div class="history-header">
                      <div class="history-title text-root">
                        {{ data.type }}
                        <span v-if="data.species">สายพันธุ์ {{ data.species }}</span> |
                        {{ data.gender }}
                      </div>
                      <div
                        class="history-status"
                        v-if="data.status === 'ไม่ผ่านเงื่อนไข'"
                        style="color: red"
                      >
                        {{ data.status }}
                      </div>
                    </div>
                    <div class="history-body">
                      <div class="image">
                        <img :src="data.image" class="image-logo" alt="" />
                      </div>
                      <div class="history-title">
                        {{ data.name }}
                      </div>
                      <div class="history-botton">
                        <router-link
                          :to="{
                            name: 'Register_pet_edit',
                            params: { id: data.id },
                          }"
                        >
                          <button type="button" class="btn see-more">แก้ไข</button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="card-body mt-3 white">
                <div class="history-empty">
                  <div class="history-empty-text">ยังไม่มีข้อมูล</div>
                </div>
              </div>
            </template>
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="button" class="button" @click="back">ย้อนกลับ</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  padding: 0;
}

.image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 15px;
}

.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 50%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 50%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}

.history-botton {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.see-more {
  background-color: #0d6efd !important;
  border-color: #dee2e6 !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}

.bbtn {
  font-family: Sarabun, sans-serif;
  font-weight: 500;
  font-size: 18px !important;
  border-radius: 8px;
  color: #fff;
  height: 44px !important;
  min-height: 44px !important;
  padding: 0 14px !important;
  letter-spacing: 0;
  text-indent: 0;
  text-transform: none;
  flex: 1;
}
</style>
